import CreateAction from '../../redux/action-utilities/action-creator';

const reducerName = 'tunnelSim';

const setFigures = new CreateAction(reducerName, 'SET_FIGURES');
export const setFiguresAction = setFigures.action;

const setSimulationId = new CreateAction(reducerName, 'SET_SIMULATION_ID');
export const setSimulationIdAction = setSimulationId.action;

const setSimulationValues = new CreateAction(reducerName, 'SET_SIMULATION_VALUES');
export const setSimulationValuesAction = setSimulationValues.action;

const clearState = new CreateAction(reducerName, 'CLEAR_STATE');
export const clearStateAction = clearState.action;

const INITIAL_STATE = {
    simulationId: null,
    figures: {},
    simulationValues: {},
};

export const tunnelSimSelector = (rootReducer) => rootReducer.tunnelSimReducer;

export default function tunnelSimReducer(state = INITIAL_STATE, action) {
    switch (action.type) {
        case setFigures.actionType:
            return {
                ...state,
                figures: {
                    ...state.figures,
                    [action.payload.name]: action.payload.figure,
                },
            };
        case setSimulationId.actionType:
            return { ...state, simulationId: action.payload };
        case setSimulationValues.actionType:
            return { ...state, simulationValues: action.payload };
        case clearState.actionType:
            return INITIAL_STATE;
        default:
            return state;
    }
}
