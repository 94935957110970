import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

function SetHtmlContent(props) {
    const { html, id, ...rest } = props;
    const divRef = useRef(null);
    const [plotTitle, setPlotTitle] = useState('');

    useEffect(() => {
        if (!html) return;
        const titleStartPosition = html.indexOf('<b>');
        const titleEndPosition = html.indexOf('<\\/b>');
        const titleLength = titleEndPosition - titleStartPosition;
        const title = html.substr(titleStartPosition, titleLength + 5);
        setPlotTitle(title.replaceAll('\\', ''));

        const funcToRemoveStart = html.indexOf('$(".');
        const funcToRemoveEnd = html.lastIndexOf(';');
        const funcToRemoveLength = funcToRemoveEnd - funcToRemoveStart - 5;
        const funcToRemove = html.substr(funcToRemoveStart, funcToRemoveLength);

        const htmlResponsiveGraph = html
            //The new version of plotly does not generate the same basic html to manipulate for responsiveness to uncomment once we have compiled again with the older version of plotly
            // .replace('"width": 840, "height": 630,', '')
            // .replace('"height: 630px;width: 840px;"', '"height: 100%;width: 100%;"')
            // .replace(').then(function(){', ', {"responsive": true}).then(function(){')
            .replace(title, '')
            .replace(funcToRemove, '');
        const parsedHtml = document.createRange().createContextualFragment(htmlResponsiveGraph);
        divRef.current.innerHTML = '';
        divRef.current.appendChild(parsedHtml);
        const plotlyEmbeddedGraph = document.getElementById(id);
        const saveButtonElement = plotlyEmbeddedGraph.getElementsByClassName(
            'modebar-container', //modebar-btn
        )[0];
        saveButtonElement.style.display = 'none';
    }, [html, id]);

    return (
        <>
            <GraphTitle id="plotTitle" dangerouslySetInnerHTML={{ __html: plotTitle }} />

            <div {...rest} id={id} ref={divRef}></div>
        </>
    );
}

SetHtmlContent.propTypes = {
    html: PropTypes.string,
    id: PropTypes.string,
};

export default SetHtmlContent;

const GraphTitle = styled.div`
    position: absolute;
    z-index: 10;
    display: flex;
    flex-direction: column;
    text-align: center;
    width: 85%;
    // margin-top: 26px;
`;
