import _ from 'lodash';

import { setIsLoadingAction } from './lead-gen.reducer';
import { leadGenService } from '../../services/poynting-services';

export function postNewLead(params) {
    return async (dispatch, getState) => {
        const { simulationId } = getState().tunnelSimReducer;
        params.id = simulationId;

        dispatch(setIsLoadingAction(true));
        return leadGenService
            .postNewLead(params, { responseType: 'blob' })
            .then((response) => {
                if (!params.isEmail) {
                    const file = new Blob([_.get(response, 'result')], { type: 'application/pdf' });
                    let pdfReportDownload = document.createElement('a');
                    let fileURL = URL.createObjectURL(file);
                    pdfReportDownload.download = 'report.pdf';
                    pdfReportDownload.href = fileURL;
                    pdfReportDownload.click();
                }
            })
            .finally(() => {
                dispatch(setIsLoadingAction(false));
            });
    };
}
