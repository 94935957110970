import antennaServiceUrls from './antenna-service-urls';
import networkService from '../network-service/network.service';

function fetchAntennas() {
    const url = antennaServiceUrls.fetchAntennas();
    return networkService.makeGet(url);
}

export default {
    fetchAntennas,
};
