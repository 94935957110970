import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

const NotFound = () => {
    return (
        <Contained>
            <h1>Oh-oh 404 - File Not Found</h1>
            <p>
                You are trying to access a file which either does not, has not or possibly even will
                not exist on this website.
            </p>

            <StyledLink to="/">Go back Home through this link</StyledLink>
        </Contained>
    );
};
export default NotFound;

NotFound.displayName = NotFound.name;

const Contained = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
`;

const StyledLink = styled(Link)`
    color: ${(props) => props.theme.primaryGrey};
`;
