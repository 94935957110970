import conductivityGraph from '../../assets/conductivity-graph.png';
import permittivityGraph from '../../assets/pertmittivity-graph.png';

export const parameterLayout = [
    { segment: 'Tunnel Dimensions', id: 'TunnelDimension' },
    {
        name: 'Height',
        value: 'height',
        unit: ' m',
        description:
            'Tunnels vary in height, but this tool assumes a constant height throughout the tunnel. Please provide the mean height of the tunnel ceiling in metres (m).',
    },
    {
        name: 'Width',
        value: 'width',
        unit: ' m',
        description:
            'Tunnels vary in width, but this tool assumes a constant width throughout the tunnel. Please provide the mean width of the tunnel ‘wall to wall’ in metres (m).',
    },
    {
        name: 'Length',
        value: 'maxLength',
        unit: ' m',
        description:
            'The assumed distance down the tunnel where you plan to implement the next radio and its antenna (in metres).',
    },
    { segment: 'Electrical Parameters of the walls', id: 'ElectricalParameters' },
    {
        name: 'Conductivity',
        value: 'conductivity',
        unit: ' S/m',
        description:
            'specified in S/m. See the figure below for typical conductivity values for rock. Note the scale at the bottom is in mS/m.',
        image: conductivityGraph,
    },
    {
        name: 'Relative permittivity',
        value: 'er',
        unit: '',
        description: 'See graph below',
        image: permittivityGraph,
    },
    {
        name: 'Roughness',
        value: 'roughness',
        unit: ' mm RMS',
        description: 'This is the mean variation of the unevenness of the walls in millimetres ',
    },
    {
        name: 'Tilt',
        value: 'tilt',
        unit: '°',
        description:
            'This is a difficult parameter to estimate. It is the mean tilt of the walls from perpendicular. Typically about 1-2 degrees.',
    },
    { segment: 'Transmitter Parameters', id: 'TransmitterParameters' },
    {
        name: 'Transmitter antenna',
        value: 'txAntenna',
        unit: '',
        description:
            'Select the antenna you plan to use at the transmitter. Note that the antennas with “HELI” in the product model name are circularly polarised and the others are all linear polarised. You can investigate the throughput of a link using antennas with different polarisations.',
    },
    {
        name: 'Transmitter distance from left wall',
        value: 'sourceXPosition',
        unit: ' m',
        description:
            'The distance of the antenna from the left wall of the tunnel in metres (m). Generally, the closer the antenna is to the centre of the tunnel, the better the link quality.',
    },
    {
        name: 'Transmitter height above ground',
        value: 'sourceYPosition',
        unit: ' m',
        description:
            'The height of the antenna from the floor of the tunnel in metres (m). Generally, the closer the antenna is to the centre of the tunnel, the better the link quality.',
    },
    {
        name: 'Transmitter power',
        value: 'txPower',
        unit: ' dBm',
        description:
            'Transmit power of the radio in dBm. Note that an Access Point (AP) may have a transmit power of 29dBm at MCS0 but only 25dBm at MCS7',
    },
    {
        name: 'Operation Frequency',
        value: 'freqMHz',
        unit: ' MHz',
        description:
            'Frequency band (in MHz) allowed by the antenna and radio system deployed. For dual band and multiband systems you will need to run the tool for each frequency separately. Wi-Fi is usually 2450MHz or 5850MHz, but you can enter any frequency.',
    },
    { segment: 'Receiver Parameters', id: 'ReceiverParameters' },
    {
        name: 'Receiver Antenna',
        value: 'rxAntenna',
        unit: '',
        description:
            'Select the antenna you plan to use at the receiver. Please note that the antennas with “HELI” in the product model name are circular polarised and the others are all linear polarised. You can investigate the throughput of a link using antennas with different polarisations.',
    },
    {
        name: 'Receiver distance from left wall',
        value: 'obsXPosition',
        unit: ' m',
        description:
            'The distance of the antenna from the left wall of the tunnel in metres (m). Generally, the closer the antenna is to the centre of the tunnel, the better the link quality.',
    },
    {
        name: 'Receiver height above ground',
        value: 'obsYPosition',
        unit: ' m',
        description:
            'The height of the antenna from the floor of the tunnel in metres (m). Generally, the closer the antenna is to the centre of the tunnel, the better the link quality.',
    },
    {
        name: 'Receiver position along tunnel',
        value: 'z',
        unit: ' m',
        description:
            'Distance down the tunnel between the antennas from the first radio unit to the second unit in metres (m)',
    },
    {
        name: 'Receiver min signal strength',
        value: 'threshold',
        unit: ' dBm',
        description:
            'The sensitivity of the receiver can be found in the data sheets. This figure represents the minimum received power where communications is possible. The link will use the lowest order modulation scheme and hence will result in the lowest data throughput. A receive signal strength must be specified that will ensure that the data rate you require is achievable. For example, an Access Point (AP) may have a minimum received power requirement  of -96dBm at MCS0 but as much as -77dBm at MCS7. The data throughput of MCS0 is lower than MCS7, hence the higher received power requirement for MCS7.',
    },
];
