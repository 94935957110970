import * as Yup from 'yup';

export const formInitialValues = {
    height: 4,
    width: 5,
    z: 50,
    sourceXPosition: 2.5,
    sourceYPosition: 2,
    obsXPosition: 2.5,
    obsYPosition: 2,
    maxLength: 200,
    minLength: 10,
    rxPolarisation: 2,
    txPolarisation: 2,
    freqMHz: '',
    conductivity: 0.02,
    er: 12,
    roughness: 4,
    tilt: 1,
    mMax: 51,
    nMax: 51,
    mMin: 1,
    nMin: 1,
    threshold: -50,
    heightSpan: '3:1:5',
    widthSpan: '3:1:5',
    plotMean: 0,
    plot1: 1,
    plot2: 1,
    plot3: 1,
    plot4: 1,
    rxGain: 10,
    rxAntenna: '',
    txAntenna: '',
    txGain: 10,
    txPower: 20,
    reCaptcha: '',
};

export const formValidationSchema = Yup.object({
    height: Yup.number().required('Height is required'),
    width: Yup.number().required('Width is required'),
    z: Yup.number().required('Position along tunnel is required'),
    sourceXPosition: Yup.number().required('Transmitter position from wall is required'),
    sourceYPosition: Yup.number().required('Transmitter height above ground is required'),
    obsXPosition: Yup.number().required('Receiver position from wall is required'),
    obsYPosition: Yup.number().required('Receiver position from wall is required'),
    maxLength: Yup.number().required('Length of the tunnel is required'),
    minLength: Yup.number(),
    rxPolarisation: Yup.number().required('Receiver polarisation is required'),
    txPolarisation: Yup.number().required('transmitter polarisation is required'),
    freqMHz: Yup.number().required('Frequency is required'),
    conductivity: Yup.number().required('Conductivity is required'),
    er: Yup.number().required('Relative permittivity is required'),
    roughness: Yup.number().required('Roughness is required'),
    tilt: Yup.number().required('Tilt degrees is required'),
    mMax: Yup.number().integer().positive(),
    nMax: Yup.number().integer().positive(),
    mMin: Yup.number().integer().positive(),
    nMin: Yup.number().integer().positive(),
    threshold: Yup.number().required('Threshold is required'),
    heightSpan: Yup.string(''),
    widthSpan: Yup.string(''),
    plotMean: Yup.number().integer(),
    plot1: Yup.number().integer(),
    plot2: Yup.number().integer(),
    plot3: Yup.number().integer(),
    plot4: Yup.number().integer(),
    rxGain: Yup.number().required('Receiver gain is required'),
    rxName: Yup.string(''),
    txGain: Yup.number().required('Transmitter gain is required'),
    txName: Yup.string(''),
    txPower: Yup.number().required('Transmitter power is required'),
    reCaptcha: Yup.string(''),
});

export const formTooltips = {
    height: 'The average height of the tunnel in meters',
    width: 'The average width of the tunnel in meters',
    z: 'The distance down the tunnel between radio units',
    sourceXPosition: 'The Position of the Transmitter expressed in meters from the left hand wall',
    sourceYPosition: 'The Position of the Transmitter expressed in meters from the tunnel floor',
    obsXPosition: 'The Position of the Receiver expressed in meters from the left hand side',
    obsYPosition: 'The Position of the Receiver expressed in meters from the tunnel floor',
    maxLength: 'The distance down the tunnel where to implement the next radio and its antenna',
    freqMHz: 'The frequency band (in MHz) allowed by the antenna and radio system deployed',
    conductivity: 'The conductivity of the walls in S/m, see Typical Values in FAQ',
    er: 'The relative permittivitty of the walls See typical Values in FAQ',
    roughness: 'The RMS height of the imperfections in the wall in millimeters',
    tilt: 'The RMS tilt of the side walls of the tunnel',
    threshold:
        'A threshold value at which the percentage of the tunnel area is greater then this figure',
    rxAntenna: 'The antenna to be used a the receiver',
    txAntenna: 'The antenna to be used a the transmitter',
    txPower: 'The power of the transmitter (dBm)',
};
