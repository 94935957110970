import _ from 'lodash';

import { setFiguresAction, setSimulationIdAction } from './tunnel-sim.reducer';
import { tunnelSimService } from '../../services/poynting-services';

export function getFigure(params) {
    return async (dispatch) => {
        return tunnelSimService.fetchResults({ params }).then((response) => {
            dispatch(
                setFiguresAction({
                    name: `figure${params.figure}`,
                    figure: _.get(response, 'result'),
                }),
            );
        });
    };
}

export function postSimulation(params) {
    return async (dispatch) => {
        return tunnelSimService.postSimulation(params).then((response) => {
            dispatch(setSimulationIdAction(_.get(response, 'result').filename));
        });
    };
}
