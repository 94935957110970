import React from 'react';
import { CardContent, Typography, Grid } from '@material-ui/core/';
import PropTypes from 'prop-types';

import { FormTextField, Card } from '../common-components';

const ElectricalParameters = (props) => {
    const { values, touched, errors, onChange, ...rest } = props;

    return (
        <Card>
            <CardContent>
                <h3>Electrical parameters of the walls</h3>
            </CardContent>
            <CardContent>
                <Grid container spacing={2} alignItems="flex-end">
                    <Grid item xs={12} sm={3}>
                        <Typography> Conductivity (S/m)</Typography>
                        <FormTextField
                            id="electrical-parameters-of-the-walls-conductivity"
                            name="conductivity"
                            defaultValue={values.conductivity}
                            helperText={touched.conductivity ? errors.conductivity : ''}
                            error={touched.conductivity && !!errors.conductivity}
                            onChange={onChange('conductivity')}
                            {...rest}
                        />
                    </Grid>
                    <Grid item xs={12} sm={3}>
                        <Typography> Relative permittivity </Typography>
                        <FormTextField
                            id="electrical-parameters-of-the-walls-relative-permittivity"
                            name="er"
                            defaultValue={values.er}
                            helperText={touched.er ? errors.er : ''}
                            error={touched.er && !!errors.er}
                            onChange={onChange('er')}
                            {...rest}
                        />
                    </Grid>
                    <Grid item xs={12} sm={3}>
                        <Typography> Roughness (mm RMS) </Typography>
                        <FormTextField
                            id="electrical-parameters-of-the-walls-roughness"
                            name="roughness"
                            defaultValue={values.roughness}
                            helperText={touched.roughness ? errors.roughness : ''}
                            error={touched.roughness && !!errors.roughness}
                            onChange={onChange('roughness')}
                            {...rest}
                        />
                    </Grid>
                    <Grid item xs={12} sm={3}>
                        <Typography> Tilt (degrees) </Typography>
                        <FormTextField
                            id="electrical-parameters-of-the-walls-tilt"
                            name="tilt"
                            type="number"
                            defaultValue={values.tilt}
                            helperText={touched.tilt ? errors.tilt : ''}
                            error={touched.tilt && !!errors.tilt}
                            onChange={onChange('tilt')}
                            {...rest}
                        />
                    </Grid>
                </Grid>
            </CardContent>
        </Card>
    );
};

ElectricalParameters.propTypes = {
    values: PropTypes.object,
    touched: PropTypes.object,
    errors: PropTypes.object,
    onChange: PropTypes.func,
};

export default ElectricalParameters;
