import { combineReducers } from 'redux';

import tunnelSimReducer from '../reducers/tunnel-sim-reducer/tunnel-sim.reducer';
import leadGenReducer from '../reducers/lead-reducer/lead-gen.reducer';
import antennaReducer from '../reducers/antenna-reducer/antenna.reducer';
import generalReducer from '../reducers/general-reducer/general.reducer';

export default combineReducers({
    tunnelSimReducer,
    leadGenReducer,
    antennaReducer,
    generalReducer,
});
