import React from 'react';
import { Container, Toolbar } from '@material-ui/core/';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import background from '../assets/background.png';
import Navbar from './navbar.component';

export const Layout = ({ children }) => {
    return (
        <>
            <BackgroundImage />
            <Navbar />
            <StyledToolbar>
                <Container>{children}</Container>
            </StyledToolbar>
        </>
    );
};

Layout.displayName = Layout.name;

Layout.propTypes = {
    children: PropTypes.node,
};

const BackgroundImage = styled.div`
    background-image: url(${background});
    position: fixed;
    min-width: 100%;
    min-height: 100%;
    background-size: cover;
    background-position: center;
    opacity: 50%;
    background-color: ${(props) => props.theme.lightGrey};
`;

const StyledToolbar = styled(Toolbar)`
    padding-left: 0px;
    padding-right: 0px;
    padding-top: 70px;
`;
