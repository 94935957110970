import { createMuiTheme } from '@material-ui/core/styles';
import { createGlobalStyle } from 'styled-components';

// Styled components theme, this is our primary theme
export const theme = {
    primaryOrange: '#F38A00',
    primaryGrey: '#434244',
    secondaryCornflowerBlue: '#204393',
    secondaryBrightBlue: '#0E509F',
    secondaryToryBlue: '#114B8B',
    secondaryCrimsonRed: '#ED2027',
    secondaryCherryRed: '#D31E46',
    secondaryAppleGreen: '#6BB345',
    secondaryTulipTreeYellow: '#E5B53A',
    secondaryBlackCoral: '#505A75',
    white: '#fff',
    black: '#000',
    whiteout: '#FBFBFB',
    darkGrey: '#868686',
    lightGrey: '#D3D3D3',
    loadingPrimaryButton: 'rgba(243, 138, 0, 0.45)',
};

// Create a theme for material UI instance, primarily used to overwrite MUI defaults
export const themeMui = createMuiTheme({
    typography: {
        fontFamily: 'century-gothic, sans-serif',
        button: {
            textTransform: 'none',
        },
    },
    palette: {
        primary: {
            main: '#F38A00',
        },
        secondary: {
            main: '#434244',
        },
    },
    breakpoints: {
        values: {
            xs: 300,
            sm: 600,
            md: 960,
            lg: 1320,
            xl: 1920,
        },
    },
});

// Global style for styled components, e.g. inject global font
export const GlobalStyles = createGlobalStyle`
h1 {
    font-family: century-gothic, sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 50px;
    line-height: 92.5%;
    letter-spacing: -0.05em;
    color: ${theme.primaryGrey};
    margin-top: 4px;
    margin-bottom: 4px;
}
h2 {
    font-family: century-gothic, sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 39.97px;
    line-height: 110%;
    letter-spacing: 0.04em;
    color: ${theme.primaryGrey};
    margin-top: 4px;
    margin-bottom: 4px;
}
h3 {
    font-family: century-gothic, sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 30px;
    line-height: 110%;
    letter-spacing: 0.04em;
    color: ${theme.primaryGrey};
    margin-top: 4px;
    margin-bottom: 4px;
}
h4 {
    font-family: century-gothic, sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 26.48px;
    line-height: 110%;
    letter-spacing: -0.02em;
    color: ${theme.primaryGrey};
    margin-top: 4px;
    margin-bottom: 4px;
}
h5 {
    font-family: century-gothic, sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 20.17px;
    line-height: 110%;
    letter-spacing: 0.02em;
    color: ${theme.primaryGrey};
    margin-top: 4px;
    margin-bottom: 4px;
}
h6 {
    display: block;
    text-align: left;
    font-family: century-gothic, sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 10px;
    line-height: 140%;
    letter-spacing: 0;
    color: ${theme.primaryGrey};
    margin-top: 4px;
    margin-bottom: 4px;
}
p {
    font-family: century-gothic, sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    line-height: 140%;
    letter-spacing: 0.04em;
    color: ${theme.primaryGrey};
    margin-top: 4px;
    margin-bottom: 4px;
}
caption {
    display: block;
    text-align: left;
    font-family: century-gothic, sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 140%;
    letter-spacing: 0;
    color: ${theme.primaryGrey};
    margin-top: 4px;
    margin-bottom: 4px;
}
a {
    font-family: century-gothic, sans-serif;
    font-size: 20px;
}
body {
    font-family: century-gothic, sans-serif;
    margin: 0;
}


@media only screen and (max-width: 1100px) {
  h1 {
    font-size: 47.8px;
  }
  h2 {
    font-size: 30.81px;
  }
  h3 {
    font-size: 27.65px;
  }
  h4 {
    font-size: 23.04px;
  }
  h5 {
    font-size: 16px;
  }
  h6 {
    font-size: 13.3px;
  }
  p {
    font-size: 16px;
  }
  caption {
    font-size: 13.3px;
  }
  a {
    font-size: 16px;
  }
}

@media only screen and (max-width: 700px) {
    h1 {
      font-size: 41.8px;
    }
    h2 {
      font-size: 29px;
    }
    h3 {
      font-size: 20.16px;
    }
    h4 {
      font-size: 16.8px;
    }
    h5 {
      font-size: 14px;
    }
    h6 {
        font-size: 11.7px;
    }
    p {
      font-size: 14px;
    }
    caption {
        font-size: 11.7px;
    }
    a {
        font-size: 14px;
    }
  }
`;
