import React from 'react';
import { CardContent, Typography, Grid } from '@material-ui/core/';
import PropTypes from 'prop-types';

import { FormTextField, Card } from '../common-components';
import { useResponsive } from '../../hooks';

const Dimensions = (props) => {
    const { values, touched, errors, onChange, ...rest } = props;
    const { isSmall } = useResponsive();

    return (
        <Card>
            <CardContent>
                <h3>Dimensions</h3>
            </CardContent>
            <CardContent>
                <Grid container spacing={2} alignItems="flex-end">
                    <Grid item xs={12} sm={3}>
                        <Typography> Height (m) </Typography>
                        <FormTextField
                            id="tunnel-dimensions-height"
                            name="height"
                            defaultValue={values.height}
                            helperText={touched.height ? errors.height : ''}
                            error={touched.height && !!errors.height}
                            onChange={onChange('height')}
                            {...rest}
                        />
                    </Grid>
                    <Grid item xs={12} sm={3}>
                        <Typography> Width (m) </Typography>
                        <FormTextField
                            id="tunnel-dimensions-width"
                            name="width"
                            defaultValue={values.width}
                            helperText={touched.width ? errors.width : ''}
                            error={touched.width && !!errors.width}
                            fullWidth={isSmall}
                            onChange={onChange('width')}
                            {...rest}
                        />
                    </Grid>
                    <Grid item xs={12} sm={3}>
                        <Typography> Length (m) </Typography>
                        <FormTextField
                            id="tunnel-dimensions-length"
                            name="maxLength"
                            defaultValue={values.maxLength}
                            helperText={touched.maxLength ? errors.maxLength : ''}
                            error={touched.maxLength && !!errors.maxLength}
                            fullWidth={isSmall}
                            onChange={onChange('maxLength')}
                            {...rest}
                        />
                    </Grid>
                </Grid>
            </CardContent>
        </Card>
    );
};

Dimensions.propTypes = {
    values: PropTypes.object,
    touched: PropTypes.object,
    errors: PropTypes.object,
    onChange: PropTypes.func,
};

export default Dimensions;
