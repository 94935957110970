import _ from 'lodash';

function setItem(key, _value) {
    if (!process.browser) {
        return null;
    }

    let value = _value;
    if (typeof value === 'object') {
        value = JSON.stringify(value);
    }
    return localStorage.setItem(key, value);
}

function getItem(key) {
    if (!process.browser) {
        return null;
    }

    if (!_.isNil(localStorage.getItem(key))) {
        const storageObjectString = localStorage.getItem(key);
        try {
            return JSON.parse(storageObjectString);
        } catch (e) {
            return storageObjectString;
        }
    }
    return null;
}

function removeItem(key) {
    if (!process.browser) {
        return null;
    }

    return localStorage.removeItem(key);
}

function clear() {
    if (!process.browser) {
        return null;
    }

    return localStorage.clear();
}

export default {
    setItem,
    getItem,
    removeItem,
    clear,
};
