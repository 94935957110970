import React from 'react';
import { Card as MaterialCard } from '@material-ui/core';
import styled from 'styled-components';
import PropTypes from 'prop-types';

const Card = (props) => {
    const { children, ...rest } = props;

    return <PrimaryCard {...rest}>{children}</PrimaryCard>;
};

Card.propTypes = {
    children: PropTypes.array,
};

export default Card;

const PrimaryCard = styled(MaterialCard)`
    box-shadow: 0 0.25rem 0.75rem rgb(0 0 0 / 5%);
    margin-bottom: 15px;
`;
