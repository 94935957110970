import React from 'react';
import {
    Button as MaterialButton,
    CircularProgress as MaterialCircularProgress,
} from '@material-ui/core';
import styled from 'styled-components';
import PropTypes from 'prop-types';

const Button = (props) => {
    const { children, isLoading, disabled, ...rest } = props;

    return (
        <PrimaryButton
            {...rest}
            variant="contained"
            color={isLoading ? 'inherit' : 'default'}
            disabled={isLoading || disabled}
        >
            {children}
            {isLoading && <CircularProgress size={25} />}
        </PrimaryButton>
    );
};

Button.propTypes = {
    children: PropTypes.string,
    type: PropTypes.string,
    isLoading: PropTypes.bool,
    disabled: PropTypes.bool,
};

export default Button;

const PrimaryButton = styled(MaterialButton)`
    color: white;
    background-color: ${(props) =>
        props.color !== 'default' ? props.theme.loadingPrimaryButton : props.theme.primaryOrange};
    box-shadow: none;
    border-radius: 3px;
    padding: 12px 17px;
    font-size: 16px;
    min-width: 136px;
    &:hover {
        background-color: ${(props) => props.theme.loadingPrimaryButton};
        box-shadow: 0px 7px 34px rgba(49, 114, 97, 0.1021);
    }
`;

const CircularProgress = styled(MaterialCircularProgress)`
    position: absolute;
    color: white;
`;
