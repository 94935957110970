import CreateAction from '../../redux/action-utilities/action-creator';

const reducerName = 'antenna';

const setIsLoading = new CreateAction(reducerName, 'SET_IS_LOADING');
export const setIsLoadingAction = setIsLoading.action;

const setAntennaList = new CreateAction(reducerName, 'SET_ANTENNA_LIST');
export const setAntennaListAction = setAntennaList.action;

const clearState = new CreateAction(reducerName, 'CLEAR_STATE');
export const clearStateAction = clearState.action;

const INITIAL_STATE = {
    isLoading: false,
    antennaList: null,
};

export const antennaSelector = (rootReducer) => rootReducer.antennaReducer;

export default function antennaReducer(state = INITIAL_STATE, action) {
    switch (action.type) {
        case setIsLoading.actionType:
            return { ...state, isLoading: action.payload };
        case setAntennaList.actionType:
            return { ...state, antennaList: action.payload };
        case clearState.actionType:
            return INITIAL_STATE;
        default:
            return state;
    }
}
