import localStorageService from '../storage-service/local-storage.service';
import config from '../../../config';

const ENV_TYPE__KEY = '@poyntingEnvType';

function saveEnvType(envType) {
    return localStorageService.setItem(ENV_TYPE__KEY, envType);
}

function loadEnvType() {
    return localStorageService.getItem(ENV_TYPE__KEY);
}

async function getBaseUrl() {
    return config.poyntingApiUrl;
}

async function getApiKey() {
    return config.poyntingApiKey;
}

export default {
    saveEnvType,
    loadEnvType,
    getBaseUrl,
    getApiKey,
};
