import _ from 'lodash';
import { PolarisationKey } from './antenna-types';

import { setIsLoadingAction, setAntennaListAction } from './antenna.reducer';
import { antennaService } from '../../services/poynting-services';

export function getAntennas() {
    return async (dispatch) => {
        dispatch(setIsLoadingAction(true));
        return antennaService
            .fetchAntennas()
            .then((response) => {
                let antennaList = _.get(response, 'result');
                if (typeof antennaList === 'object') {
                    Object.values(antennaList).forEach((antenna) => {
                        if (_.get(antenna, 'polarisation'))
                            antenna.polarisationKey = PolarisationKey[antenna.polarisation];
                    });
                    dispatch(setAntennaListAction(antennaList));
                }
            })
            .finally(() => {
                dispatch(setIsLoadingAction(false));
            });
    };
}
