import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import {
    Dialog,
    DialogContent,
    DialogTitle,
    FormHelperText,
    IconButton,
    Typography,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { Formik, Form } from 'formik';

import { Button } from '../common-components';
import { newLeadFormInitialValues, newLeadFormValidationSchema, NewLead } from '../form-components';
import { postNewLead } from '../../reducers/lead-reducer/lead-gen.action';
import { useResponsive } from '../../hooks';

export const NewLeadDialog = () => {
    const dispatch = useDispatch();
    const [leadFormOpen, setLeadFormOpen] = useState(false);
    const [formSubmitError, setFormSubmitError] = useState(null);
    const [isEmail, setIsEmail] = useState(false);
    const [submitButtonDisable, setSubmitButtonDisable] = useState(false);
    const { isSmall, isSmallWindowHeightThreshold } = useResponsive();

    const handleLeadOpen = () => {
        setLeadFormOpen(true);
    };

    const onClickEmail = () => {
        setIsEmail(true);
        handleLeadOpen();
    };

    const handleAcceptTerm = (event) => {
        setFormSubmitError(
            !event.target.checked ? 'we must have your consent to mail the results to you' : null,
        );
        setSubmitButtonDisable(!event.target.checked);
    };

    const onSubmit = (values) => {
        setLeadFormOpen(false);
        values.isEmail = isEmail;
        setFormSubmitError(null);
        dispatch(postNewLead(values));
    };

    return (
        <>
            <Dialog open={leadFormOpen}>
                <DialogTitle id="lead-form">
                    <Typography>Simulation Results</Typography>
                    <CloseButton aria-label="close" onClick={() => setLeadFormOpen(false)}>
                        <CloseIcon />
                    </CloseButton>
                </DialogTitle>
                <DialogContent>
                    <Formik
                        initialValues={newLeadFormInitialValues}
                        validationSchema={newLeadFormValidationSchema}
                        onSubmit={onSubmit}
                        validateOnBlur
                        validateOnChange={false}
                    >
                        {({
                            submitForm,
                            isSubmitting,
                            touched,
                            errors,
                            handleChange,
                            handleBlur,
                        }) => (
                            <FormContainer>
                                <Form>
                                    <NewLead
                                        touched={touched}
                                        errors={errors}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        onCheck={handleAcceptTerm}
                                    />
                                    <FormHelperText error>{formSubmitError}</FormHelperText>
                                    <Button
                                        isLoading={isSubmitting}
                                        onClick={submitForm}
                                        disabled={submitButtonDisable}
                                    >
                                        Accept
                                    </Button>
                                    <StyledBox>
                                        <StyledLink to="/contact-us">Contact Us?</StyledLink>
                                    </StyledBox>
                                </Form>
                            </FormContainer>
                        )}
                    </Formik>
                </DialogContent>
            </Dialog>
            <Footer $isSmallWindowHeightThreshold={isSmallWindowHeightThreshold}>
                <StyledButton id="emailPdf" onClick={onClickEmail} $isSmall={isSmall}>
                    Email PDF
                </StyledButton>
            </Footer>
        </>
    );
};

const StyledBox = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    height: 100%;
`;

const StyledLink = styled(Link)`
    color: ${(props) => props.theme.primaryGrey};
`;
const Footer = styled.div`
    position: ${({ $isSmallWindowHeightThreshold }) =>
        $isSmallWindowHeightThreshold ? 'relative' : 'fixed'};
    width: calc(
        100%
            ${({ $isSmallWindowHeightThreshold }) =>
                $isSmallWindowHeightThreshold ? ' - 20px' : ' - 50px'}
    );
    bottom: 0px;
`;

const StyledButton = styled(Button)`
    margin-left: 10px;
    margin-right: 10px;
    margin-bottom: 20px;
    width: ${({ $isSmall }) => ($isSmall ? '100%' : '200px')};
`;

const FormContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 840px;
`;

const CloseButton = styled(IconButton)`
    position: absolute;
    right: 3px;
    top: 3px;
`;
