import React, { useEffect, useState } from 'react';
import { CircularProgress, Tooltip } from '@material-ui/core';
import { ToggleButton, ToggleButtonGroup } from '@material-ui/lab';
import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import _ from 'lodash';

import { getFigure } from '../reducers/tunnel-sim-reducer/tunnel-sim.action';
import {
    tunnelSimSelector,
    clearStateAction,
} from '../reducers/tunnel-sim-reducer/tunnel-sim.reducer';
import { SetHtmlContent } from './common-components';
import { NewLeadDialog } from './new-lead-components/new-lead-dialog.component';
import { useResponsive } from '../hooks';
import SelectedParameters from './selected-parameters.component';

export const Graphs = () => {
    const simulation = useSelector(tunnelSimSelector);
    const [loadingGraph, setLoadingGraph] = useState(false);
    const [plotNr, setPlotNr] = useState(1);
    const [plotDescription, setPlotDescription] = useState(
        'Signal strength vs distance down tunnel',
    );
    const dispatch = useDispatch();
    const { isSmallWindowHeightThreshold } = useResponsive();
    const PlotDescriptions = Object.freeze({
        plot1: 'Signal strength vs distance down tunnel',
        plot2: 'Tunnel c/s power density at receiver',
        plot3: '3-D power density plot at receiver height',
        plot4: 'Compare polarisation performance',
    });

    useEffect(() => {
        if (simulation.simulationId && plotNr) {
            const request = { figure: plotNr, filename: simulation.simulationId };
            if (_.isNil(simulation.figures[`figure${plotNr}`])) dispatch(getFigure(request));

            setPlotDescription(PlotDescriptions[`plot${plotNr}`]);
        }
    }, [plotNr, simulation, dispatch, PlotDescriptions]);

    useEffect(() => {
        setLoadingGraph(false);
    }, [simulation, plotNr]);

    const renderGraph = () => {
        const plot = simulation.figures[`figure${plotNr}`];

        return (
            <StyledBox>
                {loadingGraph && (
                    <StyledLoading>
                        <CircularProgress />
                    </StyledLoading>
                )}
                {!loadingGraph && (
                    <InnerHTML
                        id="embeddedGraph"
                        html={plot}
                        $isSmallWindowHeightThreshold={isSmallWindowHeightThreshold}
                    />
                )}
            </StyledBox>
        );
    };

    let contents = _.isNil(simulation) ? (
        <p>
            <em>please run simulation first</em>
        </p>
    ) : (
        renderGraph()
    );

    const handleClick = (event, newPlot) => {
        if (newPlot) {
            setPlotNr(newPlot);
        }
        setLoadingGraph(true);
    };

    const returnHandler = () => {
        dispatch(clearStateAction());
    };

    return (
        <>
            <SelectedParameters />
            <StyledLink to="/" onClick={returnHandler}>
                back
            </StyledLink>
            <h1>Simulation Results</h1>
            <StyledBox>
                <StyledToggleButtonGroup value={plotNr} exclusive onChange={handleClick}>
                    <Tooltip title={PlotDescriptions['plot1']} placement="top-start" arrow>
                        <ToggleButton value={1} selected={plotNr === 1} disabled={plotNr === 1}>
                            Plot 1
                        </ToggleButton>
                    </Tooltip>
                    <Tooltip title={PlotDescriptions['plot2']} placement="top-start" arrow>
                        <ToggleButton value={2} selected={plotNr === 2} disabled={plotNr === 2}>
                            Plot 2
                        </ToggleButton>
                    </Tooltip>
                    <Tooltip title={PlotDescriptions['plot3']} placement="top-start" arrow>
                        <ToggleButton value={3} selected={plotNr === 3} disabled={plotNr === 3}>
                            Plot 3
                        </ToggleButton>
                    </Tooltip>
                    <Tooltip title={PlotDescriptions['plot4']} placement="top-start" arrow>
                        <ToggleButton value={4} selected={plotNr === 4} disabled={plotNr === 4}>
                            Plot 4
                        </ToggleButton>
                    </Tooltip>
                </StyledToggleButtonGroup>
                <Caption>{plotDescription}</Caption>
            </StyledBox>
            <StyledBox>{contents}</StyledBox>
            <NewLeadDialog />
        </>
    );
};

const StyledBox = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    height: 100%;
`;

const StyledToggleButtonGroup = styled(ToggleButtonGroup)`
    height: 36px;
    background: white;
    .MuiToggleButton-root.Mui-selected {
        color: ${(props) => props.theme.primaryGrey};
    }
    .MuiToggleButton-root {
        color: ${(props) => props.theme.primaryGrey};
    }
    margin-bottom: 15px;
`;

const StyledLink = styled(Link)`
    color: ${(props) => props.theme.primaryGrey};
`;
const StyledLoading = styled.div`
    padding-top: 80px;
`;

const InnerHTML = styled(SetHtmlContent)`
    width: 100%;
    height: ${({ $isSmallWindowHeightThreshold }) =>
        $isSmallWindowHeightThreshold ? '100%' : '54vh'};
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 90%;
`;

const Caption = styled.h5`
    color: ${(props) => props.theme.primaryOrange};
    margin-bottom: 15px;
`;
