import { applyMiddleware, createStore } from 'redux';
import ReduxThunk from 'redux-thunk';
import ReduxPromise from 'redux-promise';

import rootReducer from './root-reducer';

const bindMiddleware = (middleware) => {
    if (process.env.NODE_ENV !== 'production') {
        /* eslint-disable-next-line */
        const { composeWithDevTools } = require('redux-devtools-extension');
        return composeWithDevTools(applyMiddleware(...middleware));
    }
    return applyMiddleware(...middleware);
};

export default createStore(rootReducer, bindMiddleware([ReduxThunk, ReduxPromise]));
