import React, { useState, useEffect, useRef } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { FormHelperText, Paper } from '@material-ui/core/';
import ReCAPTCHA from 'react-google-recaptcha';
import { Formik, Form } from 'formik';
import styled from 'styled-components';

import { postSimulation } from '../reducers/tunnel-sim-reducer/tunnel-sim.action';
import { getAntennas } from '../reducers/antenna-reducer/antenna.action';
import { Button } from './common-components';
import {
    Dimensions,
    ElectricalParameters,
    Transmitter,
    Receiver,
    formInitialValues,
    formValidationSchema,
} from './form-components';
import { setSimulationValuesAction } from '../reducers/tunnel-sim-reducer/tunnel-sim.reducer';

const TunnelSim = () => {
    const dispatch = useDispatch();
    const [loadingMessage, setLoadingMessage] = useState(null);
    const reCaptchaRef = useRef();

    useEffect(() => {
        dispatch(getAntennas());
    }, [dispatch]);

    const history = useHistory();
    const reCaptchaKey = process.env.REACT_APP_POYNTING_RE_CAPTCHA_KEY;

    const onSubmit = async (values, { setSubmitting }) => {
        setSubmitting(true);
        setLoadingMessage(
            'This is quite a complicated calculation, only raise the alarm bells after around 90 seconds',
        );
        dispatch(setSimulationValuesAction(values));
        await dispatch(
            postSimulation({
                height: values.height,
                width: values.width,
                sourceXPosition: values.sourceXPosition,
                sourceYPosition: values.sourceYPosition,
                obsXPosition: values.obsXPosition,
                obsYPosition: values.obsYPosition,
                noOfZPoints: Math.ceil(values.maxLength / 2),
                noOfXPoints: Math.ceil(values.width * 20),
                noOfYPoints: Math.ceil(values.height * 20),
                z: values.z,
                maxLength: values.maxLength,
                minLength: values.minLength,
                rxPolarisation: values.rxPolarisation,
                txPolarisation: values.txPolarisation,
                freqMHz: values.freqMHz,
                conductivity: values.conductivity,
                er: values.er,
                roughness: values.roughness,
                tilt: values.tilt,
                mMax: values.mMax,
                nMax: values.nMax,
                mMin: values.mMin,
                nMin: values.nMin,
                threshold: values.threshold,
                heightSpan: values.heightSpan,
                widthSpan: values.widthSpan,
                plotMean: values.plotMean,
                plot1: values.plot1,
                plot2: values.plot2,
                plot3: values.plot3,
                plot4: values.plot4,
                rxGain: values.rxGain,
                rxAntenna: values.rxAntenna,
                txGain: values.txGain,
                txAntenna: values.txAntenna,
                txPower: values.txPower,
                reCaptcha: values.reCaptcha,
            }),
        );
        setSubmitting(false);
        history.push('/results');
    };

    return (
        <>
            <h1>Tunnel Simulator</h1>
            <br></br>
            <Formik
                initialValues={formInitialValues}
                validationSchema={formValidationSchema}
                onSubmit={onSubmit}
                validateOnBlur
                validateOnChange={false}
            >
                {({
                    submitForm,
                    isSubmitting,
                    touched,
                    errors,
                    handleChange,
                    handleBlur,
                    setFieldValue,
                    values,
                }) => (
                    <Form>
                        <StyledPaper>
                            <SectionHeading> Tunnel Definition</SectionHeading>
                            <Dimensions
                                values={values}
                                touched={touched}
                                errors={errors}
                                onChange={handleChange}
                                onBlur={handleBlur}
                            />
                            <ElectricalParameters
                                values={values}
                                touched={touched}
                                errors={errors}
                                onChange={handleChange}
                                onBlur={handleBlur}
                            />
                        </StyledPaper>
                        <StyledPaper>
                            <SectionHeading>Link Parameters</SectionHeading>
                            <Transmitter
                                values={values}
                                touched={touched}
                                errors={errors}
                                onChange={handleChange}
                                onBlur={handleBlur}
                            />
                            <Receiver
                                values={values}
                                touched={touched}
                                errors={errors}
                                onChange={handleChange}
                                onBlur={handleBlur}
                            />
                        </StyledPaper>
                        <ReCAPTCHA
                            ref={reCaptchaRef}
                            sitekey={reCaptchaKey}
                            onChange={(response) => {
                                setFieldValue('reCaptcha', response);
                            }}
                            onExpired={() => {
                                if (!isSubmitting) {
                                    reCaptchaRef.current.handleChange(null);
                                    return;
                                }
                                reCaptchaRef.current.execute();
                            }}
                        />
                        <br></br>
                        <StyledText>{loadingMessage}</StyledText>
                        <StyledButton isLoading={isSubmitting} onClick={submitForm}>
                            Simulate
                        </StyledButton>
                        <br></br>
                    </Form>
                )}
            </Formik>
        </>
    );
};

export default TunnelSim;

const StyledPaper = styled(Paper)`
    padding: 10px;
    background: rgba(0, 0, 0, 0.1);
    margin-bottom: 10px;
`;

const SectionHeading = styled.h3`
    color: ${(props) => props.theme.primaryOrange};
`;

const StyledButton = styled(Button)`
    margin-bottom: 30px;
`;
const StyledText = styled(FormHelperText)`
    color: ${(props) => props.theme.secondaryCornflowerBlue};
`;
