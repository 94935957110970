import tunnelSimulationServiceUrls from './tunnel-sim-service-urls';
import networkService from '../network-service/network.service';

function fetchResults({ params }) {
    const url = tunnelSimulationServiceUrls.fetchResults();
    return networkService.makeGet(url, { params });
}

function postSimulation(data) {
    const url = tunnelSimulationServiceUrls.postSimulation();
    return networkService.makePost(url, data);
}

export default {
    fetchResults,
    postSimulation,
};
