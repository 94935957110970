import React from 'react';
import { MenuItem, TextField } from '@material-ui/core';
import styled from 'styled-components';
import { Field } from 'formik';
import PropTypes from 'prop-types';
import _ from 'lodash';

import { regions } from '../form-components/new-lead-form-values.component';

const NewLead = (props) => {
    const { touched, errors, onCheck, ...rest } = props;

    const regionOptions = _.map(regions, (region, key) => (
        <MenuItem key={key} value={key}>
            {region}
        </MenuItem>
    ));

    return (
        <>
            <label>Name</label>
            <StyledTextField
                required
                name="name"
                id="name"
                type="text"
                variant="outlined"
                helperText={touched.name ? errors.name : ''}
                error={touched.name && !!errors.name}
                fullWidth
                {...rest}
            />
            <label>Surname</label>
            <StyledTextField
                required
                name="surname"
                id="surname"
                type="text"
                variant="outlined"
                helperText={touched.surname ? errors.surname : ''}
                error={touched.surname && !!errors.surname}
                fullWidth
                {...rest}
            />
            <label>Company email</label>
            <StyledTextField
                required
                name="companyEmail"
                id="companyEmail"
                type="text"
                variant="outlined"
                helperText={touched.companyEmail ? errors.companyEmail : ''}
                error={touched.companyEmail && !!errors.companyEmail}
                fullWidth
                {...rest}
            />
            <label>Phone No</label>
            <StyledTextField
                required
                name="phone"
                id="phone"
                type="text"
                variant="outlined"
                helperText={touched.phone ? errors.phone : ''}
                error={touched.phone && !!errors.phone}
                fullWidth
                {...rest}
            />
            <label>Region</label>
            <StyledTextField
                required
                name="region"
                id="region"
                type="text"
                variant="outlined"
                defaultValue="South Africa"
                helperText={touched.region ? errors.region : ''}
                error={touched.region && !!errors.region}
                fullWidth
                select
                {...rest}
            >
                {regionOptions}
            </StyledTextField>
            <div>
                <Field
                    required
                    name="termsAccept"
                    id="termsAccept"
                    type="checkbox"
                    {...rest}
                    onClick={onCheck}
                />
                I hereby give permission to Poynting to store my data for marketing purposes.
            </div>
        </>
    );
};

NewLead.propTypes = {
    onCheck: PropTypes.func,
    touched: PropTypes.object,
    errors: PropTypes.object,
};

export default NewLead;

const StyledTextField = styled(TextField)`
    .MuiOutlinedInput-root {
        fieldset {
            border-color: ${(props) => props.theme.primaryOrange};
        }
        :hover fieldset {
            border-color: ${(props) => props.theme.primaryOrange};
        }
    }
    border-color: ${(props) => props.theme.primaryOrange};
`;
