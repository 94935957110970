import React, { useEffect, useState } from 'react';
import { Grid, Typography, Slider, Paper, Tooltip } from '@material-ui/core';
import styled from 'styled-components';
import { useField } from 'formik';
import PropTypes from 'prop-types';

import { useResponsive } from '../../hooks';
import { formTooltips } from '../form-components/tunnel-request-form-values.component';

export default function InputSlider(props) {
    const { defaultValue, id, name, max } = props;
    const [sliderValue, setsliderValue] = useState(defaultValue);
    const [field, meta, helpers] = useField(props);
    const { isSmall } = useResponsive();

    const [tooltipOpen, setTooltipOpen] = useState(false);

    const openTooltip = () => {
        setTooltipOpen(true);
    };

    const closeTooltip = () => {
        setTooltipOpen(false);
    };

    const tooltipInfo = (
        <StyledTooltip>
            {formTooltips[name]}. For more info, click{' '}
            <Link href={`http://poynting.tech/tunnel-simulation-faq#${id}`} target="_blank">
                here
            </Link>
        </StyledTooltip>
    );
    const handleSliderValueChange = (event, updatedValue) => {
        setsliderValue(updatedValue);
        helpers.setValue(updatedValue);
        closeTooltip();
    };

    useEffect(() => {
        if (defaultValue > max) {
            setsliderValue(max);
            helpers.setValue(max);
        }
    }, [defaultValue, max, helpers]);

    return (
        <Tooltip
            title={tooltipInfo}
            interactive
            open={tooltipOpen}
            onOpen={openTooltip}
            onClose={closeTooltip}
        >
            <StyledPaper variant="outlined" $isSmall={isSmall}>
                <StyledGrid container spacing={2} direction="row">
                    <Grid item xs={10}>
                        <Slider
                            {...field}
                            {...props}
                            step={props.max < 10 ? 0.1 : 1}
                            aria-labelledby={field.id}
                            onBlur={(e) => helpers.setTouched(e)}
                            onChange={handleSliderValueChange}
                        />
                        {meta.touched && meta.error ? <div>{meta.error}</div> : null}
                    </Grid>
                    <Grid item xs={2}>
                        <Typography> {sliderValue} </Typography>
                    </Grid>
                </StyledGrid>
            </StyledPaper>
        </Tooltip>
    );
}

InputSlider.propTypes = {
    defaultValue: PropTypes.number,
    max: PropTypes.number,
    id: PropTypes.string,
    name: PropTypes.string,
    isSmall: PropTypes.bool,
};

const StyledPaper = styled(Paper)`
    border-color: ${(props) => props.theme.primaryOrange};
    max-width: ${({ $isSmall }) => ($isSmall ? '100%' : '200px')};
`;

const StyledGrid = styled(Grid)`
    width: 100%;
    margin: -4px;
    padding: 7px;
`;

const StyledTooltip = styled(Typography)`
font-size: 1rem;
color: ${(props) => props.theme.white};
max-width: 200px;
}`;

const Link = styled.a`
    color: ${(props) => props.theme.white};
`;
