import React from 'react';
import styled from 'styled-components';
import { AppBar, List, Toolbar, ListItem, ListItemText } from '@material-ui/core';

import Logo from '../assets/PoyntingLogoColor.png';

const Navbar = () => {
    const onFaqClick = () => {
        window.open('https://poynting.tech/tunnel-simulation-faq', '_blank');
    };
    const onContactUsClick = () => {
        window.open('https://poynting.tech/contact-us/ ', '_blank');
    };

    return (
        <StyledAppBar>
            <Toolbar>
                <a href="/tunnel-simulation">
                    <StyledLogo src={Logo} alt="Poynting logo" />
                </a>
                <Spacer />
                <StyledList>
                    <StyledListItem button onClick={onFaqClick}>
                        <StyledLiText primary="FAQ" />
                    </StyledListItem>
                    <StyledListItem button onClick={onContactUsClick}>
                        <StyledLiText primary="Contact Us" />
                    </StyledListItem>
                </StyledList>
            </Toolbar>
        </StyledAppBar>
    );
};

export default Navbar;

const StyledAppBar = styled(AppBar)`
    background-color: ${(props) => props.theme.primaryGrey};
    color: black;
    position: fixed;
    z-index: 10px;
    box-shadow: 0 0.25rem 0.75rem rgba(0, 0, 0, 0.05);
    justify-content: center;
`;

const Spacer = styled.div`
    flex-grow: 1;
`;

const StyledList = styled(List)`
    display: flex;
    padding: 0px;
`;

const StyledListItem = styled(ListItem)`
    width: 120px;
    text-align: center;
`;

const StyledLogo = styled.img`
    height: 50px;
`;
const StyledLiText = styled(ListItemText)`
    color: ${(props) => props.theme.primaryOrange};
`;
