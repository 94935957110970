import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';

import {
    generalSelector,
    setErrorsAction,
    setMessageAction,
    setStatusCodeAction,
} from '../reducers/general-reducer/general.reducer';

const ErrorPage = (props) => {
    const { statusCode, message } = useSelector(generalSelector);
    const { children } = props;
    const dispatch = useDispatch();

    const onClick = () => {
        dispatch(setStatusCodeAction(''));
        dispatch(setMessageAction(''));
        dispatch(setErrorsAction(''));
    };

    if (!statusCode) return <> {children} </>;

    return (
        <Contained>
            <h1>
                Oh-oh {statusCode} - {message}
            </h1>

            <StyledLink to="/" onClick={onClick}>
                Go back Home through this link
            </StyledLink>
        </Contained>
    );
};
export default ErrorPage;

ErrorPage.displayName = ErrorPage.name;

ErrorPage.propTypes = {
    children: PropTypes.object,
};

const Contained = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
`;

const StyledLink = styled(Link)`
    color: ${(props) => props.theme.primaryGrey};
`;
