import React, { useState } from 'react';
import { TextField, Tooltip as MuiTooltip, Typography } from '@material-ui/core/';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import { useResponsive } from '../../hooks';
import { formTooltips } from '../form-components/tunnel-request-form-values.component';

const FormTextField = (props) => {
    const { children, id, name, select, ...rest } = props;
    const { isSmall } = useResponsive();

    const [tooltipOpen, setTooltipOpen] = useState(false);
    const [selectOpen, setSelectOpen] = useState(false);

    const openTooltip = () => {
        setTooltipOpen(true);
    };

    const closeTooltip = () => {
        setTooltipOpen(false);
    };

    const selectOnOpen = () => {
        setSelectOpen(true);
    };

    const selectOnClose = () => {
        setSelectOpen(false);
    };

    const selectPropsOverride = {
        onOpen: selectOnOpen,
        onClose: selectOnClose,
    };

    const tooltipInfo = (
        <StyledTooltip>
            {formTooltips[name]}. For more info, click{' '}
            <Link href={`http://poynting.tech/tunnel-simulation-faq#${id}`} target="_blank">
                here
            </Link>
        </StyledTooltip>
    );

    return (
        <Tooltip
            title={selectOpen ? '' : tooltipInfo}
            open={tooltipOpen}
            onOpen={openTooltip}
            onClose={closeTooltip}
            interactive
        >
            <StyledTextField
                type="number"
                variant="outlined"
                fullWidth={isSmall}
                $isSmall={isSmall}
                id={id}
                onClick={closeTooltip}
                select={select}
                SelectProps={selectPropsOverride}
                {...rest}
            >
                {children}
            </StyledTextField>
        </Tooltip>
    );
};

FormTextField.propTypes = {
    children: PropTypes.array,
    isSmall: PropTypes.bool,
    id: PropTypes.string,
    name: PropTypes.string,
    select: PropTypes.bool,
};

export default FormTextField;

const StyledTextField = styled(TextField)`
    .MuiOutlinedInput-root {
        fieldset {
            border-color: ${(props) => props.theme.primaryOrange};
        }
        :hover fieldset {
            border-color: ${(props) => props.theme.primaryOrange};
        }
    }
    border-color: ${(props) => props.theme.primaryOrange};
    max-width: ${({ $isSmall }) => ($isSmall ? '100%' : '200px')};
`;

const StyledTooltip = styled(Typography)`
    font-size: 1rem;
    color: ${(props) => props.theme.white};
    max-width: 200px;
}`;

const Link = styled.a`
    color: ${(props) => props.theme.white};
`;

const Tooltip = styled(MuiTooltip)`
    z-index = 1;
`;
