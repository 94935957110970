import CreateAction from '../../redux/action-utilities/action-creator';

const reducerName = 'general';

const setIsLoading = new CreateAction(reducerName, 'SET_IS_LOADING');
export const setIsLoadingAction = setIsLoading.action;

const setStatusCode = new CreateAction(reducerName, 'SET_STATUS_CODE');
export const setStatusCodeAction = setStatusCode.action;

const setMessage = new CreateAction(reducerName, 'SET_MESSAGE');
export const setMessageAction = setMessage.action;

const setErrors = new CreateAction(reducerName, 'SET_ERRORS');
export const setErrorsAction = setErrors.action;

const clearState = new CreateAction(reducerName, 'CLEAR_STATE');
export const clearStateAction = clearState.action;

const INITIAL_STATE = {
    isLoading: false,
    statusCode: null,
    message: null,
    errors: null,
};

export const generalSelector = (rootReducer) => rootReducer.generalReducer;

export default function generalReducer(state = INITIAL_STATE, action) {
    switch (action.type) {
        case setIsLoading.actionType:
            return { ...state, isLoading: action.payload };
        case setStatusCode.actionType:
            return { ...state, statusCode: action.payload };
        case setMessage.actionType:
            return { ...state, message: action.payload };
        case setErrors.actionType:
            return { ...state, errors: action.payload };
        case clearState.actionType:
            return INITIAL_STATE;
        default:
            return state;
    }
}
