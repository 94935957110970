import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { CardContent, Typography, Grid, MenuItem } from '@material-ui/core/';
import PropTypes from 'prop-types';
import _ from 'lodash';

import { InputSlider, FormTextField, Card } from '../common-components';
import { antennaSelector } from '../../reducers/antenna-reducer/antenna.reducer';

const Receiver = (props) => {
    const { values, touched, errors, onChange, ...rest } = props;
    const { antennaList } = useSelector(antennaSelector);
    const antennaOptions = _.map(antennaList, (antenna) => (
        <MenuItem key={_.get(antenna, 'id')} value={_.get(antenna, 'productCode')}>
            {_.get(antenna, 'productCode')}
        </MenuItem>
    ));

    const changeReceiver = (event) => {
        const antenna = _.find(
            antennaList,
            (antenna) => _.get(antenna, 'productCode') === event.target.value,
        );
        values.rxAntenna = _.get(antenna, 'productCode');
        values.rxGain = _.get(antenna, 'gain');
        values.rxPolarisation = _.get(antenna, 'polarisationKey');
        onChange(event);
    };

    useEffect(() => {
        if (!_.isNil(antennaList) && _.isEmpty(values.rxAntenna))
            values.rxAntenna = _.get(antennaList[0], 'productCode');
    }, [antennaList, values.rxAntenna]);

    return (
        <Card>
            <CardContent>
                <h3>Receiver</h3>
            </CardContent>
            <CardContent>
                <Grid container spacing={2} alignItems="flex-end">
                    <Grid item xs={12} sm={3}>
                        <Typography> Antenna </Typography>
                        <FormTextField
                            id="receiver-parameters-antenna"
                            name="rxAntenna"
                            value={values.rxAntenna}
                            select
                            fullWidth
                            onChange={changeReceiver}
                            type="text"
                            helperText={touched.rxAntenna ? errors.rxAntenna : ''}
                            error={touched.rxAntenna && !!errors.rxAntenna}
                            {...rest}
                        >
                            {antennaOptions}
                        </FormTextField>
                    </Grid>
                    <Grid item xs={12} sm={3}>
                        <Typography> Distance from wall (m) </Typography>
                        <InputSlider
                            id="receiver-parameters-antenna"
                            name="obsXPosition"
                            defaultValue={values.obsXPosition}
                            max={values.width}
                            onChange={onChange('obsXPosition')}
                            {...rest}
                        />
                    </Grid>
                    <Grid item xs={12} sm={3}>
                        <Typography> Height above ground (m) </Typography>
                        <InputSlider
                            id="receiver-parameters-antenna"
                            name="obsYPosition"
                            defaultValue={values.obsYPosition}
                            max={values.height}
                            onChange={onChange('obsYPosition')}
                            {...rest}
                        />
                    </Grid>
                    <Grid item xs={12} sm={3}>
                        <Typography> Distance along tunnel (m) </Typography>
                        <InputSlider
                            id="receiver-parameters-distance-along-tunnel"
                            name="z"
                            defaultValue={values.z}
                            max={values.maxLength}
                            onChange={onChange('z')}
                            {...rest}
                        />
                    </Grid>
                    <Grid item xs={12} sm={3}>
                        <Typography> Minimum acceptable received signal strength (dBm) </Typography>
                        <FormTextField
                            id="receiver-parameters-signal-strength"
                            name="threshold"
                            defaultValue={values.threshold}
                            helperText={touched.threshold ? errors.threshold : ''}
                            error={touched.threshold && !!errors.threshold}
                            onChange={onChange('threshold')}
                            {...rest}
                        />
                    </Grid>
                </Grid>
            </CardContent>
        </Card>
    );
};

Receiver.propTypes = {
    values: PropTypes.object,
    touched: PropTypes.object,
    errors: PropTypes.object,
    onChange: PropTypes.func,
};

export default Receiver;
