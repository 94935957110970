import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { CardContent, Typography, Grid, MenuItem } from '@material-ui/core/';
import PropTypes from 'prop-types';
import _ from 'lodash';

import { InputSlider, FormTextField, Card } from '../common-components';
import { antennaSelector } from '../../reducers/antenna-reducer/antenna.reducer';

const Transmitter = (props) => {
    const { values, touched, errors, onChange, ...rest } = props;
    const { antennaList } = useSelector(antennaSelector);
    const [freqRange, setFreqRange] = useState('');
    const [frequencies, setFrequencies] = useState([]);
    const antennaOptions = _.map(antennaList, (antenna) => (
        <MenuItem key={_.get(antenna, 'id')} value={_.get(antenna, 'productCode')}>
            {_.get(antenna, 'productCode')}
        </MenuItem>
    ));
    const frequencyList = _.map(frequencies, (frequency) => (
        <MenuItem key={frequency} value={frequency}>
            {frequency}
        </MenuItem>
    ));

    const changeTransmitter = (event) => {
        const antenna = _.find(
            antennaList,
            (antenna) => _.get(antenna, 'productCode') === event.target.value,
        );
        values.txAntenna = _.get(antenna, 'productCode');
        values.txGain = _.get(antenna, 'gain');
        values.txPolarisation = _.get(antenna, 'polarisationKey');
        setFreqRange(_.get(antenna, 'frequencyRanges'));
        onChange(event);
    };

    useEffect(() => {
        if (!_.isNil(antennaList) && _.isEmpty(freqRange)) {
            values.txAntenna = _.get(antennaList[0], 'productCode');
            setFreqRange(_.get(antennaList[0], 'frequencyRanges'));
        }
    }, [antennaList, values.txAntenna, freqRange]);

    useEffect(() => {
        if (!freqRange) return;
        let freqValues = [];
        Object.values(freqRange).forEach((rangeLimit) => {
            freqValues.push(rangeLimit.lowerBound);
            let frequency = rangeLimit.lowerBound + 1;

            while (frequency < rangeLimit.upperBound) {
                let newEntry = Math.ceil(frequency / 100) * 100;
                if (newEntry < rangeLimit.upperBound) freqValues.push(newEntry);
                frequency = frequency + 100;
            }
            freqValues.push(rangeLimit.upperBound);
        });

        setFrequencies(_.sortBy(freqValues));
        if (_.indexOf(freqValues, values.freqMHz) === -1) values.freqMHz = _.min(freqValues);
    }, [freqRange, values.freqMHz]);

    return (
        <Card>
            <CardContent>
                <h3>Transmitter</h3>
            </CardContent>
            <CardContent>
                <Grid container spacing={2} alignItems="flex-end">
                    <Grid item xs={12} sm={3}>
                        <Typography> Antenna </Typography>
                        <FormTextField
                            id="transmitter-parameters-antenna"
                            name="txAntenna"
                            value={values.txAntenna}
                            select
                            onChange={changeTransmitter}
                            fullWidth
                            type="text"
                            helperText={touched.txAntenna ? errors.txAntenna : ''}
                            error={touched.txAntenna && !!errors.txAntenna}
                            {...rest}
                        >
                            {antennaOptions}
                        </FormTextField>
                    </Grid>
                    <Grid item xs={12} sm={3}>
                        <Typography> Distance from wall (m) </Typography>
                        <InputSlider
                            id="transmitter-parameters-antenna"
                            name="sourceXPosition"
                            defaultValue={values.sourceXPosition}
                            max={values.width}
                            onChange={onChange('sourceXPosition')}
                            {...rest}
                        />
                    </Grid>
                    <Grid item xs={12} sm={3}>
                        <Typography> Height above ground (m) </Typography>
                        <InputSlider
                            id="transmitter-parameters-antenna"
                            name="sourceYPosition"
                            defaultValue={values.sourceYPosition}
                            max={values.height}
                            onChange={onChange('sourceYPosition')}
                            {...rest}
                        />
                    </Grid>
                    <Grid item xs={12} sm={3}>
                        <Typography> Transmitter power (dBm) </Typography>
                        <FormTextField
                            id="transmitter-parameters-transmit-power"
                            name="txPower"
                            defaultValue={values.txPower}
                            helperText={touched.txPower ? errors.txPower : ''}
                            error={touched.txPower && !!errors.txPower}
                            onChange={onChange('txPower')}
                            {...rest}
                        />
                    </Grid>
                    <Grid item xs={12} sm={3}>
                        <Typography> Frequency (MHz)</Typography>
                        <FormTextField
                            id="frequency-channel"
                            name="freqMHz"
                            value={values.freqMHz}
                            select
                            fullWidth
                            helperText={touched.freqMHz ? errors.freqMHz : ''}
                            error={touched.freqMHz && !!errors.freqMHz}
                            onChange={onChange('freqMHz')}
                            {...rest}
                        >
                            {frequencyList}
                        </FormTextField>
                    </Grid>
                </Grid>
            </CardContent>
        </Card>
    );
};

Transmitter.propTypes = {
    values: PropTypes.object,
    touched: PropTypes.object,
    errors: PropTypes.object,
    onChange: PropTypes.func,
};

export default Transmitter;
