import React from 'react';
import { Route, Switch } from 'react-router';

import { Layout } from './components/layout.component.js';
import Home from './components/home.component';
import { Graphs } from './components/graphs.component';
import NotFound from './components/404-not-found.component';
import ErrorPage from './components/error-page.component';

const App = () => {
    return (
        <Layout>
            <ErrorPage>
                <Switch>
                    <Route exact path="/" component={Home} />
                    <Route exact path="/results" component={Graphs} />
                    <Route component={NotFound} />
                </Switch>
            </ErrorPage>
        </Layout>
    );
};

export default App;

App.displayName = App.name;
