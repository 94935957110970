import * as Yup from 'yup';
import { blacklist } from './email-blacklist';

export const newLeadFormInitialValues = {
    name: '',
    surname: '',
    companyEmail: '',
    phone: '',
    termsAccept: true,
    isEmail: false,
    id: '',
    region: 'SouthAfrica',
};

export const regions = {
    Africa: 'Africa',
    Asia: 'Asia',
    Europe: 'Europe',
    USA: 'North America',
    Australia: 'Australia',
    SouthAmerica: 'South America',
    Other: 'Other',
};

export const newLeadFormValidationSchema = Yup.object({
    name: Yup.string().required('Name is required'),
    surname: Yup.string().required('Surname is required'),
    companyEmail: Yup.string()
        .email()
        .required('Please enter a Valid Email Address')
        .test('companyEmail', 'Please use a Company Email', (value) => {
            if (value) {
                let validEmail = blacklist.every((domain) => {
                    if (value.includes(domain)) return false;
                    return true;
                });
                return validEmail;
            }
        }),
    phone: Yup.string().required('Phone number is required'),
    termsAccept: Yup.boolean().oneOf(
        [true],
        'we must have your consent to mail the results to you',
    ),
    isEmail: Yup.boolean(),
    id: Yup.string(),
    region: Yup.string().required(
        'Please select a region, so that the relevant department could contact and assist',
    ),
});
