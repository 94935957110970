import LeadGenServiceUrls from './lead-gen-service-urls';
import networkService from '../network-service/network.service';

function postNewLead(data, config) {
    const url = LeadGenServiceUrls.postNewLead();
    return networkService.makePost(url, data, config);
}

export default {
    postNewLead,
};
