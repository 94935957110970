import axios from 'axios';
import _ from 'lodash';
import store from '../../../redux/store';

import createBaseUrlInterceptor from './base-url-interceptor';
import createApiKeyInterceptor from './api-key-interceptor';
import config from '../../../config';
import {
    setErrorsAction,
    setMessageAction,
    setStatusCodeAction,
} from '../../../reducers/general-reducer/general.reducer';

const axiosInstance = axios.create();
createBaseUrlInterceptor(axiosInstance);
createApiKeyInterceptor(axiosInstance);

const errorHandler = (error) => {
    store.dispatch(setStatusCodeAction(error.response.status));
    store.dispatch(
        setErrorsAction(
            error.response.data.errors ? error.response.data.errors : error.response.data,
        ),
    );
    store.dispatch(
        setMessageAction(
            error.response.data.message ? error.response.data.message : error.response.statusText,
        ),
    );
    return { result: 'API-Error' };
};

function makeGet(url, config) {
    return getDefaultConfig().then((defaultConfig) => {
        const responseManipulator = _.get(
            config,
            'responseManipulator',
            defaultConfig.responseManipulator,
        );
        const { defaultRequestConfig } = defaultConfig;
        const requestConfig = _.merge(defaultRequestConfig, config);

        return axiosInstance
            .get(url, requestConfig)
            .then((response) => responseManipulator(response))
            .catch((error) => errorHandler(error));
    });
}

function makePost(url, data, config) {
    return getDefaultConfig().then((defaultConfig) => {
        const responseManipulator = _.get(
            config,
            'responseManipulator',
            defaultConfig.responseManipulator,
        );
        const { defaultRequestConfig } = defaultConfig;
        const requestConfig = _.merge(defaultRequestConfig, config);

        return axiosInstance
            .post(url, data, requestConfig)
            .then((response) => responseManipulator(response))
            .catch((error) => errorHandler(error));
    });
}

function makePut(url, data, config) {
    return getDefaultConfig().then((defaultConfig) => {
        const responseManipulator = _.get(
            config,
            'responseManipulator',
            defaultConfig.responseManipulator,
        );
        const { defaultRequestConfig } = defaultConfig;
        const requestConfig = _.merge(defaultRequestConfig, config);

        return axiosInstance
            .put(url, data, requestConfig)
            .then((response) => responseManipulator(response))
            .catch((error) => errorHandler(error));
    });
}

function makeDelete(url, data, config) {
    return getDefaultConfig().then((defaultConfig) => {
        const responseManipulator = _.get(
            config,
            'responseManipulator',
            defaultConfig.responseManipulator,
        );
        const { defaultRequestConfig } = defaultConfig;
        let requestConfig = _.merge(defaultRequestConfig, config);
        requestConfig = _.merge(requestConfig, { data });

        return axiosInstance
            .delete(url, requestConfig)
            .then((response) => responseManipulator(response))
            .catch((error) => errorHandler(error));
    });
}

async function getDefaultConfig() {
    const defaultRequestConfig = {
        headers: {
            'api-version': config.apiVersion,
        },
    };

    return {
        defaultRequestConfig,
        responseManipulator: (response) => {
            return { result: response.data };
        },
    };
}

export default {
    makeGet,
    makePost,
    makeDelete,
    makePut,
};
