import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import { ThemeProvider } from 'styled-components';
import { MuiThemeProvider, StylesProvider } from '@material-ui/core/styles';

import App from './App';
import registerServiceWorker from './registerServiceWorker';
import store from './redux/store';
import { themeMui, theme, GlobalStyles } from './themes/theme';
import './styles/main.css';

require('dotenv').config();

const baseUrl = document.getElementsByTagName('base')[0].getAttribute('href');
const rootElement = document.getElementById('root');

ReactDOM.render(
    <BrowserRouter basename={`${baseUrl}/tunnel-simulation`}>
        <MuiThemeProvider theme={themeMui}>
            <ThemeProvider theme={theme}>
                <StylesProvider injectFirst>
                    <GlobalStyles />
                    <Provider store={store}>
                        <App />
                    </Provider>
                </StylesProvider>
            </ThemeProvider>
        </MuiThemeProvider>
    </BrowserRouter>,
    rootElement,
);

registerServiceWorker();
