import React from 'react';

import TunnelSim from './tunnel-sim.component';

const Home = () => {
    return (
        <>
            <TunnelSim />
        </>
    );
};
export default Home;

Home.displayName = Home.name;
