import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { Container, Drawer, Typography } from '@material-ui/core';
import { ChevronRight, ChevronLeft } from '@material-ui/icons';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import _ from 'lodash';

import { tunnelSimSelector } from '../reducers/tunnel-sim-reducer/tunnel-sim.reducer';
import { parameterLayout } from './common-components/simulation-parameters';

const SelectedParameters = () => {
    const { simulationValues } = useSelector(tunnelSimSelector);
    const [drawerState, setDrawerState] = useState(false);

    const parameters = _.map(parameterLayout, (parameter) => {
        if (_.get(parameter, 'segment'))
            return (
                <tr key={parameter.segment}>
                    <TdSubHeading>
                        <b>{parameter.segment}</b>
                    </TdSubHeading>
                </tr>
            );
        if (_.get(parameter, 'name'))
            return (
                <tr key={parameter.name}>
                    <Td key={parameter.name}>
                        <Parameter>{parameter.name} :</Parameter>
                    </Td>
                    <Td>
                        <ParameterValue>
                            {_.get(simulationValues, parameter.value)}
                            {parameter.unit}
                        </ParameterValue>
                    </Td>
                </tr>
            );
    });

    const toggleDrawer = () => {
        setDrawerState(!drawerState);
    };

    return (
        <>
            <AnchoredOpen onClick={toggleDrawer} />
            <StyledDrawer
                id="ParametersDrawer"
                anchor="left"
                open={drawerState}
                onClose={toggleDrawer}
                variant="persistent"
            >
                <AnchoredClose onClick={toggleDrawer} />
                <TableContainer>
                    <StyledH4>Selected Parameters</StyledH4>
                    <table>
                        <thead>
                            <tr>
                                <Th>Parameter</Th>
                                <Th>Value</Th>
                            </tr>
                        </thead>
                        <tbody>{parameters}</tbody>
                    </table>
                </TableContainer>
            </StyledDrawer>
        </>
    );
};

SelectedParameters.propTypes = {
    simulationValues: PropTypes.object,
};

export default SelectedParameters;

const AnchoredOpen = styled(ChevronRight)`
    position: absolute;
    top: 50vh;
    left: 8px;
    z-index: 1;
`;

const AnchoredClose = styled(ChevronLeft)`
    position: absolute;
    top: 50vh;
    right: -25px;
`;

const StyledDrawer = styled(Drawer)`
    .MuiDrawer-paper {
        background: ${(props) => props.theme.white};
        max-width: 85%;
        overflow: visible;
        z-index: 100;
    }
`;

const StyledH4 = styled.h4`
    padding: 6px;
`;

const TableContainer = styled(Container)`
    overflow: auto;
    margin-top: 70px;
`;

const Parameter = styled(Typography)`
    color: ${(props) => props.theme.primaryOrange};
    font-weight: bold;
`;

const ParameterValue = styled(Typography)`
    color: ${(props) => props.theme.primaryGrey};
`;

const Th = styled.th`
    text-align: left;
    color: ${(props) => props.theme.secondaryBlackCoral};
`;

const Td = styled.td`
    padding: 4px 0;
`;

const TdSubHeading = styled.td`
    padding-top: 12px;
`;
